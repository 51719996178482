import './bootstrap';
import '../assets/styles/app.scss';

import { createApp, h } from 'vue';
import { createInertiaApp, Head, Link } from '@inertiajs/vue3';
import { createPinia } from 'pinia';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

import { ZiggyVue } from 'ziggy-js';
import { Ziggy } from '@/ziggy';
import { i18n } from '@/i18n.js';
import * as Sentry from '@sentry/vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import scrollIntoView from '@/Directives/ScrollIntoView.js';
import transition from '@/Directives/transition.js';
import visible from '@/Directives/visible.js';
import { dispatch, listen } from '@/Directives/event.js';
import { watch } from '@/Directives/watch.ts';

import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fas);
library.add(fab);
library.add(far);
dom.watch();

const pinia = createPinia();

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)});

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            environment: import.meta.env.VITE_APP_ENV,
        });

        app.use(pinia)
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(i18n)
            .component('v-select', vSelect)
            .component('Head', Head)
            .component('Link', Link)
            .directive('scroll-into-view', scrollIntoView)
            .directive('transition', transition)
            .directive('visible', visible)
            .directive('listen', listen)
            .directive('dispatch', dispatch)
            .directive('watch', watch)
            .mount(el);

        return app;
    },
    progress: {
        color: '#009BD3',
    },
});
