/*
* caveats:
* - Chrome has a minimum timeout of 1 second for inactive tabs.
* - Inactive tabs may be throttled to save bandwidth/cpu
* @see https://developer.mozilla.org/en-US/docs/Web/API/Window/setTimeout#reasons_for_delays_longer_than_specified
*/

export const logoutAfterInactivity = {
    timeout: 60000 * 120,  // 120 minutes in milliseconds. matches config/session.php::lifetime
    intervalRef: 0,
    intercept: function (axios) {
        // listen for outgoing axios requests,
        // and (re)set a timeout to logout after [timeout] milliseconds.
        axios.interceptors.request.use((config) => {
            if (logoutAfterInactivity.intervalRef !== 0) {
                clearInterval(logoutAfterInactivity.intervalRef);
            }

            logoutAfterInactivity.intervalRef = setTimeout(() => {
                window.location.assign("/logout");
            }, logoutAfterInactivity.timeout);
            return config;
        });
    }
}
